@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

// GENERAL
body {
//  margin: 0;
//  padding: 0;
  font-family: "Poppins", sans-serif;
//  box-sizing: border-box;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
 // font-family: sans-serif;
  box-sizing: border-box;
}

* {
  -webkit-text-fill-color: inherit !important;
}
.w-5{
  width: 4% !important;
}
.header {
  display: flex;
  width: calc(100vw - 256px - 50px);
  margin-left: auto;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.field-container,
.field-container-number {
  font-weight: bold;

  label {
    font-size: 19px;
    font-weight: 600;
    color: #00009d;
    margin-bottom: -10px; //razmak izmedju polja
    margin-top: -10px;
    margin-left: 8px;
  }

  input {
    margin-bottom: 5px;
  }
}

.field-container-number-totals {
  font-weight: bold;

  label {
    font-size: 19px;
    font-weight: bold;
    color: #f80707;
    margin-bottom: -10px; //razmak izmedju polja
    margin-top: -10px;
    margin-right: 15px;
  }

  input {
    margin-bottom: 5px;
  }
}

label {
  font-weight: 600;
  color: #00009d;
  margin-bottom: -10px; //razmak izmedju polja
  margin-top: -10px;
  margin-right: 8px;
  font-size: 19px;
}

.minWidth80{
  min-width: 80px;
}

.minWidth120{
  text-align: end;
  min-width: 120px;
}

.col-form-right {
  text-align: right !important;
  margin-right: 3px;
}

.label-box {
  text-align: right !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.label-box-do {
  text-align: right !important;
  padding-inline: 0px !important;
  margin-left: 12px;
}

.label-box-div>label:after {
  content: "";
}

.label-box-div :focus {
  outline: none;
  box-shadow: none;
  border: 3px solid rgb(34, 171, 12);
}

.form-group {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 19px;
}

.col-form-label {
  padding:  0px 5px !important ;
  align-self: center;
  font-weight: bold;
  font-size: 19px;
  color: #00009d;
  margin-right: -12px;
}

.col-form-label-ovlascenja {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  align-self: center;
  font-weight: bold;
  font-size: 12px;
  color: #00009d;
  margin-right: -12px;
}

.row:not(:last-child) {
  padding-bottom: 2px !important;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

input[type="text"] {
  width: 100%;
  height: 27px;
  border: 1px solid #d2d6da;
  border-radius: 9px;
  font-size: 13px;
  color: black;
  padding-left: 15px;
  text-transform: capitalize;
  margin-bottom: 2px;
  margin-right: 3px;
  font-weight: bold; // za placeholder
}

input:focus {
  outline: none;
  box-shadow: none;
  border: 3px solid rgb(34, 171, 12);
}

.field-container :focus {
  outline: none;
  box-shadow: none;
  border: 3px solid rgb(34, 171, 12);
}

input:-webkit-autofill {
  border: 3px solid darkorange;
}

.field-alignment {
  text-align: right;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white !important;
  border: 1px solid #ffffff !important;
  color: red !important;
  margin-left: 10px;
  max-width: 90%;
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
}

.form-controlvr:disabled,
.form-controlvr[readonly] {
  background-color: white !important;
  border: 1px solid #ffffff !important;
  color: #000000 !important;
  max-width: 100%;
  text-align: left;
  padding-right: 15px;
}

.form-controlss:disabled,
.form-controlss[readonly] {
  background-color: white !important;
  border: 1px solid #ffffff !important;
  color: #000000 !important;
  margin-left: 10px;
  max-width: 120px;
  text-align: center;
  padding-left: 7px;
}

.form-controlst:disabled,
.form-controlst[readonly] {
  background-color: #7fb4f7 !important;
  border: 1px solid #ffffff !important;
  color: #000000 !important;
  margin-left: 0px;
  max-width: 100%;
  text-align: left;
  padding-left: 7px;
}

 .submitField{
  background-color: #7fb4f7 !important;
  border: 1px solid #7fb4f7 !important;
  color: #000000 !important;
  margin-left: 0px;
  max-width: 100%;
  text-align: left;
  padding-left: 7px;
  pointer-events: none;
  cursor: none;
} 

.form-controlst[readonly] {
  background-color: #7fb4f7 !important;
  border: 1px solid #ffffff !important;
  color: #000000 !important;
  margin-left: 0px;
  max-width: 100%;
  text-align: left;
  padding-left: 7px;
}

.total:disabled,
.total[readonly] {
  background-color: white !important;
  border: 2px solid #007bff !important;
  color: red !important;
  max-width: 100%;
  text-align: right;
  padding-right: 5px;
  font-size: 20px;
}

.total123:disabled,
.total123[readonly] {
  background-color: #7fb4f7 !important;
  border: 2px solid #7fb4f7 !important;
  color: #000000 !important;
  outline: #3498db;
  margin-left: 10px;
  max-width: 100%;
  text-align: right;
  padding-right: 5px;
}

::placeholder {
  color: #a6a6a6;
  font-size: 14px;
}

input[type=number] {
  border: none !important;  
}

input[type="number"] {
  text-align: right;
  width: 100%;
  height: 27px;
  border: 1px solid #d2d6da;
  border-radius: 9px;
  font-size: 13px;
  color: #101010;
  padding-left: 15px;
  text-transform: capitalize;
  font-weight: bold;
  padding-right: 5px;
}

input[type="number"]:read-only {
  color: #000000;
}

input[type="date"] {
  width: 100%;
  height: 27px;
  border: 1px solid #d2d6da;
  border-radius: 9px;
  font-size: 13px;
  color: #818fa5;
  padding-left: 15px;
  text-transform: capitalize;
  font-weight: bold;
}

.react-datepicker__input-container {
  width: 100%;
  height: 27px;
  border: 1px solid #d2d6da;
  border-radius: 9px;
  font-size: 13px;
  color: black;
  text-transform: capitalize;
  margin-bottom: 2px;
  margin-right: 3px;
  font-weight: bold; // za placeholder
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// uklanjanje dugmica na numerickim poljima za Mozilu
input[type="number"] {
  -moz-appearance: textfield;
}

.select-style {
  background-color: rgb(0, 255, 255);
  width: 100%;
  height: 27px;
  border: 1px solid #d2d6da;
  border-radius: 9px;
  font-size: 13px;
  color: #818fa5;
  padding-left: 15px;
  text-transform: capitalize;
  margin-bottom: 2px;
  margin-right: 3px;
  font-weight: bold; // za placeholder

  option {
    background-color: white;
  }
}

input[type="password"] {
  width: 100%;
  height: 27px;
  border: 1px solid #d2d6da;
  border-radius: 9px;
  font-size: 13px;
  color: #818fa5;
  padding-left: 15px;
  text-transform: capitalize;
  margin-bottom: 2px;
  margin-right: 3px;
  font-weight: bold;
}

.password-container {
  width: 100%;
  position: relative;
  display: flex;
}

.password-container input[type="password"],
.password-container input[type="text"] {
  width: 100%;
}

.far fa-eye {
  position: relative;
  top: 58%;
  right: 4%;
  cursor: pointer;
  color: #818fa5;
}


input[type="select"] {
  width: 100%;
  height: 42px;
  font-size: 13px;
  color: #818fa5;
  padding-left: 15px;
  text-transform: capitalize;
}

svg select {
  width: 100%;
  height: 42px;
  font-size: 13px;
  color: #818fa5;
  padding-left: 15px;
  text-transform: capitalize;
}

input[type="image"] {
  margin: 1px;
}

.page-content, .page-content-sidebar {
  padding: 3px 4px 0 5px;
  background-size: "cover";
  background-position: "center";
  background-color: #7fb4f7;

  a {
    svg {
      background-color: white;
      fill: black;
      width: 20%;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0;
    }
  }
}

.navlink{
  color: #00008b;
  &:hover{
    color: #5252d1;
  }
}

.page-content-sidebar {
  padding: 0px 4px 0 255px;
  // background-size: "cover";
  // background-position: "center";
  // background-color: #7fb4f7;

  // a {
  //   svg {
  //     background-color: white;
  //     fill: black;
  //     width: 20%;
  //     height: 20px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     padding-left: 0;
  //   }
  // }
}

// BUTTONS
.button-dark {
  background-color: #060b26;
  color: #fff;
  border-radius: 6px;
  outline: none;
  border: none;
}

.btn-sm {
  padding: 8px 17px;
  font-size: 13px;
}

.btn-md {
  padding: 10px 25px;
  font-size: 15px;
}

.btn-lg {
  padding: 12px 32px;
  font-size: 17px;
}

.dugme-brisanje,
.dugme-stampa,
.dugme-izmjena,
.dugme-sacuvaj {
  background-color: gray;
  border: black 3px solid;
  border-radius: 10px;
  width: 40px;
  cursor: pointer;
}

.dugme-sacuvaj:hover {
  box-shadow: 0 0px 6px 0.2rem #a7288a;
}

.dugme-sacuvaj:focus {
  box-shadow: 0 0 0.2rem #7e1e68;
}

.dugme-sacuvaj:disabled {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  box-shadow: 0 0 0 0 !important;
}

.btn-report button {
  font-size: 19px;
  font-weight: 800 !important;
  color: #00009d !important;
  background-color: #e1e1e1 !important;
  border-color: #4d4dff !important;
  text-align: left !important;
  margin-right: 1px;
  margin-bottom: 1px;
  width: -webkit-fill-available;
}

.btn-report button:hover {
  background-color: #d3cbcb !important;
}

.btn-report button:focus {
  background-color: #7ec3f5 !important;
  margin-right: 1px;
  margin-bottom: 1px;
}

.btn-report-red button:focus {
  background-color: #7ec3f5 !important;
  margin-right: 1px;
  margin-bottom: 1px;
}

.btn-report-red button {
  font-size: 19px;
  font-weight: 800 !important;
  color: red !important;
  background-color: #e1e1e1 !important;
  border-color: #4d4dff !important;
  text-align: left !important;
  margin-right: 1px;
  margin-bottom: 1px;
  width: -webkit-fill-available;
}

.dugme-clear {
  background-color: red;
  border: black 3px solid;
  border-radius: 10px;
  width: 55px;
}

.button-yes {
  background-color: #04c;
  color: white;
  border-radius: 10px;
  width: 55px;
}

.button-no {
  border-radius: 10px;
  width: 55px;
  margin-right: 5px;
}

.button-cancel {
  border-radius: 10px;
  margin-right: 5px;
}

p-calendar {
  padding: 0 !important;
  min-width: 85px !important;
}

// HEADERS
.section-title {
  height: 54px;
  margin-bottom: 20px;

  h1 {
    font-size: 27px;
    font-weight: 700;
    color: #3a416f;
    margin-bottom: 0;
  }
}

.title-options-group-container {
  display: flex;
  align-items: center;
  position: relative;
  top: 70px;

  .title-options-group {
    padding: 10px;
    margin-left: 25px;
  }
}

.title-options {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toogle-switch {
    position: relative;
    top: 2px;
  }

  &-group {
    display: flex;
    height: 50px;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border: none;
    padding: 10px 5px;

    button {
      display: flex;
      align-items: center;
      position: relative;
      top: 1px;
      background-color: transparent;
      border: none;
      margin-left: 15px;
      font-size: 13px;

      &:first-child {
        margin-left: 0;
      }

      .option {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2e2e2e;
        border-radius: 6px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);

        &:hover {
          transition: 0.2s all;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
        }
      }

      .green {
        background-color: #0d6efd;
      }

      .blue {
        background-color: #060b26;
      }

      .red {
        background-color: #fd5c70;
      }

      span {
        display: inline-block;
        font-size: 13px;
        color: #060b26;
        padding-left: 10px;
      }
    }
  }
}

.title-options-extrabutton {
  display: flex;

  .posting-button {
    margin-right: 35px;
  }
}

.col-up {
  .toogle-switch {
    position: relative;
    top: 6px;
    left: 30px;
  }
}

.show-unfinished {
  display: flex;
  align-items: center;

  label {
    font-size: 13px;
    color: #060b26;
    margin-bottom: 0;
    margin-left: 5px;
    cursor: pointer;
  }

  .form-check-input {
    margin-left: 0 !important;
    margin-top: 0 !important;
    position: relative;
    cursor: pointer;
  }
}

.style-header {
  align-items: left;
}

// SIDEBAR
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  overflow: auto;
  z-index: 999;
  background-color: white;
  padding-top: 10px;

  .logo {
    height: auto;
    width: 70%;
    margin-top: 16px;
    margin-left: -5%;
    margin-right: 5%;
    margin-bottom: 20px;
  }

  .menu {
    width: 100%;
    padding-left: 0;

    &-list {
      display: flex;
      justify-content: center;
      padding-bottom: 25px;
      list-style: none;

      .menu-link {
        display: flex;
        justify-content: start;
        align-items: center;
        text-decoration: none;

        .icon {
          width: 20px;

          svg {
            height: 1rem;
            width: 1rem;
            fill: black;
          }
        }

        .link-name {
          width: 100px;
          font-size: 18px;
          color: black;
          padding-left: 15px;
        }
      }
    }
  }

  &-toggle {
    margin-bottom: 65px;

    a {
      svg {
        background-color: white;
        fill: black;
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0;
      }
    }
  }
}

.collapsed {
  width: 140px !important;

  .menu {
    padding-left: 0;

    .navbar-toggle {
      a {
        margin-left: 0;
      }
    }

    .menu-list {
      padding-left: 0;

      .menu-link {
        justify-content: center;

        .link-name {
          display: none;
        }
      }
    }
  }
}

.sidebar-last-child:last-child {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.button-close {
  margin-left: 12rem;
  margin-top: -16px;
}

.header-company-name {
  color: #fafafa;
  font-size: 30px;
}

// NAVBAR
.navbar {
  display: flex;
  align-items: center;
  background-color: #015092;

  .hr {
    border: 10px solid green;
    border-radius: 5px;
    width: 100%;
  }

  .search {
    position: relative;
    background-color: red;

    input {
      width: 220px;
    }

    &-icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      width: 16px;
    }
  }

  .profile {
    display: flex;
    align-items: center;

    &-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      .profile-icon {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .profile-name {
      display: inline-block;
      padding-left: 15px;
      font-size: 15px;
      color: #fafafa;
    }
  }
}

//LOGIN
.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 70px 40px;
  border-radius: 25px;
  background: #fafafa;
  box-shadow: 3px 5px 25px #888888;
  width: 80%;
  max-width: 500px;
  transform: translate(-50%, -50%);
}

.smallForm {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px 25px 25px;
  border-radius: 20px;
  background: #7fb4f7;
  box-shadow: 3px 5px 25px #080808;
//  width: 100%;
//  max-width: 500px;
  transform: translate(-50%, -50%);
}

h1 {
  text-align: center;
  color: #0d6efd;
}

.form {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.filter {
  &__menu {
    margin: 0.125rem auto;
  }

  &__option {
    background-color: white;

    &--is-focused {
      background-color: lightblue;
    }
  }

  &__group {
    padding: 0;
  }

  &__menu-portal {
    border: 1px solid darkblue;
  }
}

// autocomplit
.testttttt {
  border: unset;
  border-radius: 0%;
}

.MuiAutocomplete-popperDisablePortal {
  height: 20px;
}

// Klase za Home(Poslovne aplikacije-Infosistem)
.pageHeader {
  text-align: center;
  color: black;
  font-weight: bold;
}

i.page-caption {
  vertical-align: baseline;
  font-size: 1.2em;
  align-items: center;
  justify-content: center;
}

.info {
  margin-bottom: 10px;
}

.company-info {
  display: flex;
  align-items: start;
  justify-content: left;
  margin-top: 50px;
  color: darkblue;
  width: calc(100vw  - 256px - 50px);
  margin-left: auto;
}

.info-icons {
  width: 25px;
  height: 27px;
  margin-right: 15px;
}

.barcode-icon {
  height: 40px;
//  margin-right: 15px;
  margin-top: 1px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: white;
  border: black 3px solid;
  border-radius: 10px;
  width: 38px;
  cursor: pointer;
}

h1 {
  font-size: 3rem;
  color: white;
}

h2 {
  font-size: 2;
  color: white;
  margin-top: 1rem;
  text-align: center;
}

.pageFooter {
  position: fixed;
  bottom: 0;
  width: calc(100% - 260px);
  text-align: center;
}

.TotalSum {
  position: fixed;
  bottom: 0;
}

.container-promet-footer {
  padding-right: 15px !important;
  background: #7fb4f7;
  color: #00009d;
}

.pageFooter a {
  font-size: 1.5rem;
  text-decoration: none;
  color: white;
}

// Tabela
.td-broj {
  text-align: right;
}

.table tr {
  font-size: 1.5rem;
}

.control-box {
  margin-bottom: 2px;
}

.control-box {
  padding-right: 10px !important;
}

.row {
  display: flex;
  margin-right: -14px !important;
  margin-left: -13px !important;
}

.nav-item.toolbaritem a {
  padding-top: 3px;
  padding-bottom: 3px;
  min-width: 100px;
}

.title-container {
  display: flex;
//  margin-top: -32px;
}

.title-container-report {
  display: flex;
//  margin-top: -32px;
//  margin-bottom: -23px;
}

.title-header {
  flex: 1;
}

.title-header h1 {
  text-align: center;
  margin-bottom: 0px;
}

.container-promet-footer {
  padding-right: 15px !important;
  background: #7fb4f7;
  color: #00009d;
}

// BUTTONS
.button-dark {
  background-color: green;
  border-radius: 6px;
  outline: none;
  border: none;
  font-weight: bold;
  box-shadow: 0 0px 6px 0.2rem #a7288a;
  box-shadow: 0 0 0 0.2rem #7e1e68;
}

.btn-sm {
  padding: 8px 17px;
  font-size: 13px;
}

.btn-md {
  padding: 10px 25px;
  font-size: 15px;
}

.btn-lg {
  padding: 12px 32px;
  font-size: 17px;
}

//polje pocrveni kad je fokusirano
.inputWithError :focus {
  outline: none;
  box-shadow: none;
  border: 2px solid red;

  label {
    font-size: 21px;
  }
}

//polje pocrveni kad je fokusirano
.inputWithError-columns :focus {
  outline: none;
  box-shadow: none;
  border: 2px solid red;

  label {
    font-size: 19px;
  }
}

//polje pocrveni kad je vrijednost manja od 0
.less-then-0 {
  label {
    font-size: 17px;
    font-weight: 600;
    color: #00009d;
    margin-bottom: -10px;
    margin-top: -10px;
    margin-right: 8px;
  }

  background-color: red;
  font-weight: bold;
  text-align: right;
}

.button-reports {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.clients-textinput {
  background-color: #fafafa;
}

.autocomplete-items div:hover {
  color: white;
  background-color: rgb(0, 255, 255);
}

.autocomplete-active {
  background-color: rgb(7, 16, 26) !important;
  color: #0d0303;
}

.MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  border: none;
  height: 23px;
  padding-left: 30px;
  padding: 4px 0px 0px 10px !important;
  font-weight: bold;
}

.MuiFormControl-root {
  border-radius: 9px;
  height: 27px;
  background-color: rgb(0, 255, 255);
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  background-color: white;
 }

 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root :focus {
  background-color: white;
 }

.MuiInputBase-root {
  height: 27px !important;
}

.MuiInput-root:before,
.MuiInput-root:after {
  border: none !important;
  outline: none !important;
}

.MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
  border: none;
}

// muix
// muix texfield
.css-z-7c6am-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  font-weight: 600 !important;
  color: #00009d !important;
}
// muix label
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 14px !important;
}

// muix input
.MuiFormControl-root  {
  & .MuiInputBase-input { 
  padding: 0 15px !important;
  border: none !important;
  margin: 0 !important;
 }
}
// muix label
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  padding: 0 !important;
}

// placeholder
.css-11d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

span.error-message {
  width: 100%;
  color: red;
  font-size: 11px;
  font-family: cursive;
  text-wrap: nowrap;
}

.sidebar {
  width: 300px !important;
  min-height: 100vh;
  padding: 0;
  overflow: auto;
  font-size: 1rem !important;
}

.bg-gradient-primary {
  background-color: #ffffff;
  color: #060b26;
  background-size: cover;
}

.pointer {
  background-color: white;
  border: black 3px solid;
  border-radius: 10px;
  width: 40px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.disabled {
  background-color: red;
  border: black 3px solid;
  border-radius: 10px;
  width: 40px;
  width: 40px;
  margin-right: 5px;
  margin-bottom: 20px;
  height: 40px;
  pointer-events: none;
}

svg disabled {
  fill: gray;
}

.settings {
  background-color: #015092;
  border-radius: 50px;
  width: 40px;
  height: 50px;
}

.naslov-ikonice {
  flex: 0 0 auto;
}

.tableCells {
  flex: 0 0 auto;
}

.divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: 3.6rem;
}

.datatable {
  background: #ffffff;
  margin: 0 0 0 0;
  font-size: 0.75rem !important;
  height: 70vh;
}

.container-promet-header {
  margin-bottom: 2px !important;
  padding-left: 0px !important;
  padding-right: 15px !important;
}

$md-radio-checked-color: rgb(200, 0, 0);
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;

.RadioButton {
  margin: 16px 0;
  margin-bottom: -15px;

  input[type="radio"] {
    display: none;

    &:checked+label:before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }

    &:checked+label:after {
      transform: scale(1);
    }
  }

  label {
    display: flex;
    height: $md-radio-size;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    font-size: 19px;
    font-weight: bold;
    color: #00009d;

    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }

    &:after {
      top: $md-radio-size / 2 - $md-radio-checked-size / 2;
      left: $md-radio-size / 2 - $md-radio-checked-size / 2;
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
    }
  }
}

.radio-box {
  padding-bottom: 40px;
  margin-left: 0px;
  margin-right: -82px;
}

.form-field-container {
  background: #7fb4f7;
  margin-left: -70px;
  margin-right: -82px;
}

.form-field-container2 {
  background: #7fb4f7;
  margin-left: 0px;
}

.nab-bar-svg {
  width: 40px !important;
  height: 30px !important;
  margin-bottom: 5px;

  .svg {
    background-color: white;
    border-radius: 7px;
    color: black;
  }
}

.modal-buttons {
  width: max-content;
  justify-content: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

#loader {
  position: absolute;
  background-color: lightblue;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

// Responsiv
@media screen and (max-width: 1900px) {
  div.btn-report button {
    font-size: small;
  }
}

// Responsiv
@media screen and (max-width: 1900px) {
  div.btn-report-red button {
    font-size: small;
  }
}

@media screen and (min-width:1025px) and (max-width: 1400px) {
  ::placeholder {
    font-size: x-small;
  }

  #media-query-nav {
    font-size: x-small;
    height: 50%;
    margin-bottom: 0 !important;
  }

  #username,
  #password,  #media-query-input-text  {
    font-size: xx-small;
    height: 25px;
    // sirina za mozilu
    width: -moz-available;
  }

  div.page-content-sidebar {
    padding-left: 175px;
  }

  #font-size-menu {
    font-size: small;
    margin-top: 0px;
    align-items: normal;
  }

  #button-close {
    margin-left: 6.4rem;
    margin-top: -17px;
  }

  #font-size-SubMenu {
    font-size: small;
    padding-bottom: 30px;
    margin-top: 0px;
    margin-bottom: -15px;
    align-items: normal;
  }

  h3 {
    font-size: 30px;
  }

  #company-info-media-query {
    font-size: x-small;
  }

  ul.flex-column {
    margin: auto;
  }

  #company-doo-media-query {
    font-size: small;
  }

  svg.info-icons {
    height: 15px;
    width: 15px;
  }

  #media-query-title-company {
    font-size: large;
    padding: 0;
  }

  #media-query-title,#media-query-aplication {
    font-size: large;
    padding: 0;
    text-align: center;
  }

  #media-query-site {
    font-size: x-small;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  h2 {
    font-size: 1;
    z-index: 1;
  }

  .MuiAutocomplete-root .MuiInput-root .MuiInput-input {
    border: none;
    height: 20px;
    padding-left: 30px;
    padding: 4px 0px 0px 10px !important;
    font-weight: bold;
    font-size: xx-small;
  }

  .MuiFormControl-root {
    border-radius: 9px;
    height: 25px;
    background-color: rgb(0, 255, 255);
    margin-top: 2px;
  }

  .MuiAutocomplete-option {
    font-size: xx-small;
  }

  #media-query {
    font-size: x-small;
    height: 20% !important;
  }

  #media-query-label {
    font-size: x-small;
    width: 70%;
  }

  #media-query-label-report {
    font-size: x-small;
    width: 15%;
    margin-left: 15px;
  }

  #media-query-label-report-year {
    font-size: x-small;
    width: 10%;
  }

  #media-query-label-to {
    font-size: x-small;
    width: 16%;
  }

  #media-query-label-DateTo {
    font-size: x-small;
    margin-left: 23px;
  }

  #media-query-input {
    font-size: x-small;
    width: 115%;
    height: 25px;
    margin-right: 10px;
  }

  #media-query-input-to {
    font-size: x-small;
    width: 125%;
    height: 25px;
  }

  #media-query-label-sum {
    font-size: x-small;
    width: 100%;
    padding-left: 0px;
  }

  #media-query-label-finReport {
    font-size: x-small;
    width: 15%;
  }

  #media-query-label-to-finReport {
    font-size: x-small;
    width: 22%;
  }

  #media-query-input-finReport , #media-query-input-to-finReport{
    font-size: x-small;
    width: 87%;
    height: 25px;
  }

  #sidebarMenuFirst {
    font-size: x-small;
    width: 165px !important;
  }

  #media-query-sidebar {
    font-size: x-small;
  }

  div.login-container {
    font-size: x-small;
    width: auto;
  }

  #media-query-rows {
    font-size: xx-small;
    width: 150vh;
    empty-cells: initial;
    text-align: right;
  }

  #media-query-rows-text {
    font-size: xx-small;
    width: 150vh;
    empty-cells: initial;
  }

  #media-query-datatable {
    row-gap: 10px;
  }

  #media-query-table-buttons,  #media-query-radioButton {
    padding-left: 10px;
    font-size: xx-small;
  }

  @media screen and (max-width: 1600px) {
  .RadioButton label {
    font-size: xx-small;
  //  padding-bottom: 37px;
   // margin-right: 0px;
  } 
}

  #media-query-row {
    font-size: x-small;
  }

  #loader {
    height: 50px;
    width: 50px;
  }

  #media-query-showsidebar {
    font-size: 1rem;
    height: 23px;
  }

  svg.pointer {
    width: 30px;
    height: 23px;
    border-radius: 6px;
    border-width: 2px;
  }

  div.login-container {
    font-size: x-small;
    width: auto;
  }

  button.dugme-clear {
    width: 40px;
    font-size: x-small;
  }

  input.dugme-sacuvaj,
  input.dugme-stampa,
  svg.dugme-brisanje {
    width: 30px;
    border-width: 2px;
  }
}

@media screen and (min-width:769px) and (max-width: 1024px) {
  #media-query-row {
    font-size: x-small;
    padding: 12px;
  }
}

@media screen and (max-width: 768px) {
  #media-query-row {
    font-size: x-small;
  }

  .header-company-name {
    margin-left: 0;
  }

  .page-content-sidebar {
    padding: 0;
  }

  .pageFooter {
    width: auto;
  }
}